import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

setTimeout(function () {
   ScrollTrigger.refresh();

   var services = document.querySelector(".services");
   if (services) {
      ScrollTrigger.create({
         trigger: '.services',
         //scroller: scroller,
         start: "-3.472vw top",
         end: "bottom-=3.472vw top",
         //markers: true,
         onEnter: () => gsap.to('.menu-btn-link-icon', {
            fill: "#121221"
         }),
         onLeave: () => gsap.to('.menu-btn-link-icon', {
            fill: "#fff"
         }),
         onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
            fill: "#fff"
         }),
         onEnterBack: () => gsap.to('.menu-btn-link-icon', {
            fill: "#121221"
         }),
      });
   }
   var reviews = document.querySelector(".reviews");
   if (reviews) {
      ScrollTrigger.create({
         trigger: '.reviews',
         //scroller: scroller,
         start: "-3.472vw top",
         end: "bottom-=3.472vw top",
         markers: false,
         onEnter: () => gsap.to('.menu-btn-link-icon', {
            fill: "#121221"
         }),
         onLeave: () => gsap.to('.menu-btn-link-icon', {
            fill: "#121221"
         }),
         onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
            fill: "#fff"
         }),
         onEnterBack: () => gsap.to('.menu-btn-link-icon', {
            fill: "#121221"
         }),
      });
   }
   var cta = document.querySelector(".cta");
   if (cta) {
      ScrollTrigger.create({
         trigger: '.cta',
         //scroller: scroller,
         start: "-3.472vw top",
         end: "bottom-=3.472vw top",
         markers: false,
         onEnter: () => gsap.to('.menu-btn-link-icon', {
            fill: "#fff"
         }),
         onLeave: () => gsap.to('.menu-btn-link-icon', {
            fill: "#fff"
         }),
         onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
            fill: "#121221"
         }),
         onEnterBack: () => gsap.to('.menu-btn-link-icon', {
            fill: "#fff"
         }),
      });
   }

   mm.add("(max-width: 480px)", () => {
      var pinMoreProject = document.querySelector(".pin-more-project");
      if (pinMoreProject) {
         ScrollTrigger.create({
            trigger: '.pin-more-project',
            //scroller: scroller,
            start: "-3.472vw top",
            end: "bottom-=3.472vw top",
            markers: false,
            onEnter: () => gsap.to('.menu-btn-link-icon', {
               fill: "#fff"
            }),
            onLeave: () => gsap.to('.menu-btn-link-icon', {
               fill: "#121221"
            }),
            onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
               fill: "#121221"
            }),
            onEnterBack: () => gsap.to('.menu-btn-link-icon', {
               fill: "#fff"
            }),
         });
      }
   });

}, 1000);